<template>
  <div class="wrapper" :style="{height:height}">
    <div id="bmap"></div>
    <div style="position: absolute; right: 10px; top: 10px;">
      <input id="searchId" type="hidden"/>
      <el-autocomplete
        v-model="searchValue"
        :debounce="500"
        :fetch-suggestions="querySearch"
        :trigger-on-focus="false"
        clearable
        placeholder="查找地址"
        size="small">
        <i slot="prefix" class="el-icon-search el-input__icon"></i>
        <template slot-scope="{ item }">
          <div
            style="font-size: 11px"
            @click="handleItemClick(item.province,item.city,item.district,item.street,item.streetNumber,item.business)">
            {{item.business}}
          </div>
        </template>
      </el-autocomplete>
    </div>
  </div>
</template>

<script>
  const BMap = window.BMap;
  export default {
    components: {},
    props: {
      height: {
        type: String,
        default: "600px",
      },
    },
    data() {
      return {
        searchValue: "",
        map: null,
        marker: null,
      };
    },
    methods: {
      initMap(centerLng, centerLat) {
        setTimeout(() => {
          this.marker = null;
          this.map = new BMap.Map("bmap");
          this.map.enableScrollWheelZoom();
          let point;
          if (centerLng && centerLat) {
            point = new BMap.Point(centerLng, centerLat);
            this.map.centerAndZoom(point, 18);
            this.marker = new BMap.Marker(point);
            this.map.addOverlay(this.marker);
          } else {
            point = new BMap.Point(114.35, 30.51);
            this.map.centerAndZoom(point, 5);
          }
          this.map.addEventListener("click", e => {
            this.onLatLngChange(e.point);
          });
        }, 200);
      },
      handleItemClick(province, city, district, street, streetNumber, business) {
        this.searchValue = business;
        this.setPlace(province + city + district + street + streetNumber + business);
      },
      setPlace(searchAddress) {
        this.local = new BMap.LocalSearch(this.map, {
          onSearchComplete: () => {
            let point = this.local.getResults().getPoi(0).point;
            this.map.centerAndZoom(point, 18);
            this.onLatLngChange(point);
          },
        });
        this.local.search(searchAddress);
      },
      querySearch(queryString, cb) {
        let ac = new BMap.Autocomplete({
          input: "searchId",
          location: this.map,
          onSearchComplete: function(result) {
            let searchValueList = [];
            for (let i = 0; i < result.getNumPois(); i++) {
              let itemObj = {};
              if (result.getPoi(i)) {
                itemObj.province = result.getPoi(i).province;
                itemObj.city = result.getPoi(i).city;
                itemObj.district = result.getPoi(i).district;
                itemObj.street = result.getPoi(i).street;
                itemObj.streetNumber = result.getPoi(i).streetNumber;
                itemObj.business = result.getPoi(i).business;
                searchValueList.push(itemObj);
              }
            }
            cb(searchValueList);
          },
        });
        ac.search(queryString);
      },
      onLatLngChange(point) {
        if (!this.marker) {
          this.marker = new BMap.Marker(point);
          this.map.addOverlay(this.marker);
        }
        this.marker.setPosition(point);
        this.$emit("lat-lng-change", point);
      },
    },
  };
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
}

#bmap {
  height: 100%;
}
</style>
