<template>
  <div class="wrapper">
    <div id="pac-card" class="pac-card">
      <div>
        <div id="title">Search</div>
        <div id="strict-bounds-selector" class="pac-controls">
          <input id="use-location-bias" type="checkbox" value="" checked/>
          <label for="use-location-bias">Bias to map viewport</label>
          <input id="use-strict-bounds" type="checkbox" value=""/>
          <label for="use-strict-bounds">Strict bounds</label>
        </div>
      </div>
      <div id="pac-container">
        <input id="pac-input" type="text" placeholder="Enter a location"/>
      </div>
    </div>
    <div id="gmap"></div>
    <div id="infowindow-content">
      <span id="place-name" class="title"></span><br/>
      <span id="place-address"></span>
    </div>
  </div>
</template>

<script>
  import googleMapUtil from "@/util/googleMapUtil";

  export default {
    components: {},
    props: {
      height: {
        type: String,
        default: "500px",
      },
    },
    data() {
      return {
        dialogVisible: false,
      };
    },
    methods: {
      initMap(centerLng, centerLat) {
        let hasCenter = centerLng && centerLat;
        googleMapUtil.initMap("gmap", hasCenter ? 15 : 3, centerLng, centerLat).then(map => {
          let google = window.google;
          const input = document.getElementById("pac-input");
          const biasInputElement = document.getElementById("use-location-bias");
          const strictBoundsInputElement = document.getElementById("use-strict-bounds");
          const options = {
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            types: ["establishment"],
          };
          const autocomplete = new google.maps.places.Autocomplete(input, options);
          autocomplete.bindTo("bounds", map);
          const infowindow = new google.maps.InfoWindow();
          const infowindowContent = document.getElementById("infowindow-content");
          infowindow.setContent(infowindowContent);
          const marker = new google.maps.Marker({
            map,
            anchorPoint: new google.maps.Point(0, -29),
          });
          if (hasCenter) {
            marker.setPosition({lat: centerLat, lng: centerLng});
            marker.setVisible(true);
          }
          autocomplete.addListener("place_changed", () => {
            infowindow.close();
            marker.setVisible(false);
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
              // User entered the name of a Place that was not suggested and
              // pressed the Enter key, or the Place Details request failed.
              window.alert("No details available for input: '" + place.name + "'");
              return;
            }
            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
              map.fitBounds(place.geometry.viewport);
            } else {
              map.setCenter(place.geometry.location);
              map.setZoom(17);
            }
            marker.setPosition(place.geometry.location);
            this.onLatLngChange(place.geometry.location);
            marker.setVisible(true);
            infowindowContent.children["place-name"].textContent = place.name;
            infowindowContent.children["place-address"].textContent = place.formatted_address;
            infowindow.open(map, marker);
          });
          autocomplete.setTypes([]);
          biasInputElement.addEventListener("change", () => {
            if (biasInputElement.checked) {
              autocomplete.bindTo("bounds", map);
            } else {
              autocomplete.unbind("bounds");
              autocomplete.setBounds({east: 180, west: -180, north: 90, south: -90});
              strictBoundsInputElement.checked = biasInputElement.checked;
            }
            input.value = "";
          });
          strictBoundsInputElement.addEventListener("change", () => {
            autocomplete.setOptions({
              strictBounds: strictBoundsInputElement.checked,
            });
            if (strictBoundsInputElement.checked) {
              biasInputElement.checked = strictBoundsInputElement.checked;
              autocomplete.bindTo("bounds", map);
            }
            input.value = "";
          });
          map.addListener("click", (mapsMouseEvent) => {
            marker.setPosition(mapsMouseEvent.latLng);
            this.onLatLngChange(mapsMouseEvent.latLng);
          });
          marker.addListener("click", ({latLng}) => {
            infowindow.close();
            this.onLatLngChange(latLng);
          });
        });
      },
      onLatLngChange(point) {
        this.$emit("lat-lng-change", point.toJSON());
      },
    },
  };
</script>

<style lang="scss">
.pac-container {
  z-index: 3000 !important;
}
</style>
<style lang="scss" scoped>
.wrapper {
  height: 500px;
  position: relative;
}

#gmap {
  height: 100%;
}

#description {
  font-size: 15px;
  font-weight: 300;
}

#infowindow-content .title {
  font-weight: bold;
}

#infowindow-content {
  display: none;
}

#gmap #infowindow-content {
  display: inline;
}

.pac-card {
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  margin: 10px;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2500;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-left: 12px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 400px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}
</style>